<template>
  <v-container fluid>

    <v-row>
      <v-col lg="3" sm="12">
        <BackButton :handler="goBack"/>
      </v-col>
    </v-row>
      <div class="d-flex justify-space-between align-center w-full">
        <div
          class="mb-2 mt-6"
          style="font-size: 24px;font-weight: 600"
        >
          Promo codes
        </div>
        <v-spacer></v-spacer>

        <div class="d-flex justify-space-between gap-x-2">
          <v-btn
              v-if="promos.length"
              class="export-button text-capitalize"
              height="48"
            @click="exportCodes"
            ><SvgIcon text="Export">
            <template v-slot:icon>
              <ExportIcon />
            </template>
          </SvgIcon></v-btn
          >

          <v-btn
            class="bg-blue text-capitalize"
            dark
            height="48"
            @click="openParticipantsDialogue = true"
            >
            <AddIcon class="mx-2"/>
            Add Emails</v-btn
          >

        </div>
      </div>
    <v-card class="shadow  rounded-5 mt-8">
      <table class="table ">
        <thead>
          <tr class="tr-rounded tr-neon opacity-70">
            <th>SR #</th>
            <th>Code</th>
            <th>Name</th>
            <th>Email</th>
            <!-- <th>Used by</th> -->
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, key) in promos" :key="data.id">
            <td>
              {{ key + 1 + (page - 1) * 10 }}
            </td>
            <td>
              {{ data.promotion_code }}
            </td>
            <td>
              {{ data.name }}
            </td>
            <td>
              {{ data.email }}
            </td>
            <!-- <td>
              N/A
            </td> -->
            <td>
              {{
                data.status_id == 1
                  ? "Active"
                  : data.status_id == 2
                  ? "Inactive"
                  : "Used"
              }}
            </td>
            <td>
              <v-btn
                icon
                :color="
                  data.status_id == 1
                    ? 'success'
                    : data.status_id == 2
                    ? 'error'
                    : 'warning'
                "
                @click="toggleStatus(data.id, data.status_id)"
              >
                <v-icon>
                  {{
                    data.status_id == 1
                      ? "mdi-check"
                      : data.status_id == 2
                      ? "mdi-close"
                      : "mdi-lock-clock"
                  }}
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card>
    <v-pagination
        class="new-pagination"
      v-if="totalPages"
      v-model="page"
      :length="Math.ceil(totalPages / perPage)"
    ></v-pagination>
    <v-dialog
      v-model="openParticipantsDialogue"
      scrollable
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">
          <v-row no-gutters>
            <v-col md="7" class="text-left mt-5">
              Add Emails
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="form_bg">
          <v-container style="margin-top:20px; margin-bottom:-20px">
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-file-input
                  label="Customers list"
                  v-model="participant_document"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon
                  outlined
                  required
                  background-color="#fff"
                >
                </v-file-input>
              </v-col>
              <v-col cols="12" md="2" lg="2">
                <v-btn
                  small
                  block
                  color="white"
                  style="color:black; height:30px; margin-top:10px"
                  dark
                  @click="downloadSampleFile()"
                >
                  Sample Xlsx
                  <v-icon right dark>
                    mdi-download
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2 white--text blue-color" @click="closeModal()"
            >Close</v-btn
          >

          <v-btn class="ma-2 white--text teal-color" @click="saveEmails()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import BackButton from "@/components/Common/BackButton.vue";
import AddIcon from "@/assets/images/misc/plus-icon.svg";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: {SvgIcon, ExportIcon, AddIcon, BackButton},
  watch: {
    page() {
      this.loadPromotionDetails();
    },
  },
  mounted() {
    if (this.$route.params.data) {
      let data = JSON.parse(atob(this.$route.params.data));
      this.id = data;
      this.loadPromotionDetails();
    }
  },
  computed: {},
  data() {
    return {
      openParticipantsDialogue: false,
      id: null,
      page: 1,
      totalPages: 1,
      perPage: 10,
      promos: [],
      participant_document: null,
    };
  },
  methods: {
    exportCodes(){
      this.showLoader("Updating...");
      this.$http
          .get("venues/promotions/export-codes/" + this.id, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.status == 200) {
              this.downloadFile(response, "Promo_code_export_");
              this.showSuccess("Downloaded successfully");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
            this.hideLoader();
      });
    },
    downloadSampleFile() {
      this.openFile("import/promo-customers.xlsx");
    },
    closeModal() {
      this.participant_document = null;
      this.openParticipantsDialogue = false;
    },
    toggleStatus(id, status) {
      if (status == 1 || status == 2) {
        this.showLoader("Updating...");
        this.$http
          .post("venues/promotions/manage/toggle/" + id)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.showSuccess("Updated successfully");
              this.loadPromotionDetails();
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
      } else {
        this.showError("Cannot activate expired code");
      }
    },
    async loadPromotionDetails() {
      this.showLoader("Loading..");
      await this.$http
        .get("venues/promotions/manage/" + this.id, {
          params: {
            page: this.page,
            per_page: this.perPage,
          },
        })
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            this.promos = data;
            this.totalPages = response.data.total;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    goBack() {
      this.$router.push({ name: "Promotions" });
    },
    saveEmails() {
      if (!this.participant_document) {
        this.showError("Please upload a file");
        return;
      }
      this.showLoader();
      let formData = new FormData();
      if (
        this.participant_document != null &&
        typeof this.participant_document != "string"
      ) {
        formData.append("participant_document", this.participant_document);
      }
      formData.append("id", this.id);

      this.$http
        .post(
          `venues/promotions/manage${this.id != null ? "/" + this.id : ""}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Emails added successfully");
            this.loadPromotionDetails();
            this.closeModal();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
  },
};
</script>
